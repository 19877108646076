import Vue from 'vue';

// Object initial state
const initialState = () => ({
  campanhas: [],
  loading: false,
  sorteios: [],
});

// State object
const state = initialState();

// Getter object
const getters = {
  getCampanhas(state) {
    return state.campanhas;
  },

  getLoading: (state) => state.loading,

  getSorteios: (state) => state.sorteios,
};

// Actions
const actions = {
  fetchCsv(_, { campanha, email, endpoint, dataHoraInicial = '', dataHoraFinal = '', filtro }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = endpoint;
      const retornaLink = email && email.length > 0 ? false : true;
      const data = {
        anoCampanha: campanha.ano,
        identificacaoCampanha: campanha.identificacao,
        retornaLink: retornaLink,
        emailEnviar: email && email.length > 0 ? email : undefined,
        filtro: filtro && Object.keys(filtro).length ? filtro : undefined,
      };
      if (dataHoraInicial && dataHoraFinal) {
        data.dataHoraInicial = dataHoraInicial;
        data.dataHoraFinal = dataHoraFinal;
      }
      const res = await comunicacao.send(url, {
        method: 'POST',
        data: data,
      }, '120000'); // Timeout de 2 minutos
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          mensagem: res.data.mensagem,
          retorno: res.data.retorno,
          download: retornaLink,
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
          retorno: res.data.retorno || null,
        });
      }
    });
  },

  fetchCampanhas({ commit }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/campanha/v1/campanhas';
      const resposta = await comunicacao.send(url, { method: 'GET' });

      if (resposta.data.status == 200) {
        commit('setCampanhas', resposta.data.retorno);
        resolve({ status: 200, mensagem: 'Lista de Campanhas recuperada com sucesso' });
      } else {
        commit('setCampanhas', []);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  fetchSorteios({ commit }, { campanha, paginacao, filtro }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/campanha/v1/campanhas';
      const resposta = await comunicacao.send(url, { method: 'GET' });

      commit('setLoading', true);

      if (resposta.data.status == 200) {
        let resultados = [];

        resposta.data.retorno.forEach((c) => {
          if (campanha.identificacao === c.identificacao && campanha.ano === c.ano) {
            c.sorteios.forEach((sorteio) => {
              if (filtro && filtro !== '') {
                if (
                  (sorteio.descricao !== undefined &&
                    sorteio.descricao !== null &&
                    sorteio.descricao.includes(filtro)) ||
                  sorteio.data.includes(filtro)
                ) {
                  resultados.push({
                    ano: c.ano,
                    campanha: c.identificacao,
                    id: sorteio.id,
                    data: sorteio.data,
                    descricao: sorteio.descricao,
                    finalizado: sorteio.finalizado,
                  });
                }
              } else {
                resultados.push({
                  ano: c.ano,
                  campanha: c.identificacao,
                  id: sorteio.id,
                  data: sorteio.data,
                  descricao: sorteio.descricao,
                  finalizado: sorteio.finalizado,
                });
              }
            });
          }
        });

        //filtrar data
        //filtrar descricao

        const resultadosPaginado = resultados.slice(
          (paginacao.paginaAtual - 1) * paginacao.totalPorPagina,
          paginacao.paginaAtual * paginacao.totalPorPagina
        );

        commit('setSorteios', { resultado: resultadosPaginado, total: resultados.length });
        resolve({ status: 200, mensagem: 'Lista de Campanhas recuperada com sucesso' });
      } else {
        commit('setSorteios', []);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }

      commit('setLoading', false);
    });
  },

  alterarCampanha({ commit }, { campanha }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/campanha/v1/campanhas/alterarCampanha';

      commit('setLoading', true);

      const resposta = await comunicacao.send(url, { method: 'PUT', data: campanha });

      commit('setLoading', false);

      if (resposta.data.status == 201) {
        resolve({ status: 201, mensagem: 'Campanha alterada com sucesso' });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  },

  cadastrarLoteCpfsRestritos({ commit }, { campanha, listaCpfs }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/campanha/v1/cpfsrestritos/inserirLote';

      commit('setLoading', true);

      const resposta = await comunicacao.send(
        url,
        {
          method: 'POST',
          data: {
            campanha,
            listaCpfs,
          },
        },
        60000
      );

      commit('setLoading', false);

      if (resposta.data.status == 200) {
        resolve({
          status: 200,
          mensagem: 'Restrições inseridas com sucesso na campanha!',
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem, retorno: resposta.data.retorno });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setCampanhas(state, data) {
    state.campanhas = data;
  },

  setSorteios(state, data) {
    state.sorteios = data;
  },

  setLoading(state, data) {
    state.loading = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
