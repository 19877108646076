<template>
  <div>
    <sidebar-item
      v-if="this.campanha && this.tipoUsuario.toLowerCase() == 'industria'"
      :link="{
        name: 'Home',
        icon: 'fas fa-industry text-dark',
        path: '/homeIndustria',
      }"
    />

    <sidebar-item
      v-if="this.campanha && this.tipoUsuario.toLowerCase() == 'rede'"
      :link="{
        name: 'Home',
        icon: 'fas fa-igloo text-dark',
        path: '/homerede',
      }"
    />

    <ItemSiderbar v-for="(pagina, idx) in listaSidebar" :key="idx" :dados="pagina" />

    <!-- <sidebar-item
      v-if="
        this.campanha &&
        this.configuracoes.MODULO_RASPADINHA_ALTERNATIVA &&
        ['lojista'].includes(this.tipoUsuario.toLowerCase())
      "
      :link="{
        name: !this.configuracoes.MUDAR_TEXTO_RASPADINHAS ? 'Raspadinhas' : 'Giros',
        icon: 'fas fa-award text-dark',
        path: '/raspadinhasAlternativas',
      }"
    />

    <sidebar-item
      v-if="
        this.campanha &&
        this.configuracoes.MODULO_CADASTRO_COMPRAS &&
        ['lojista'].includes(this.tipoUsuario.toLowerCase())
      "
      :link="{ name: 'Cadastrar Compras', icon: 'ni ni-cart text-dark', path: '/compras' }"
    /> -->
  </div>
</template>

<script>
import { Permissoes } from '../../../lib/Helper';

import listaPaginas from './listaPaginas.js';
import ItemSiderbar from '../ItemSiderbar.vue';

export default {
  name: 'MenuSorteios',

  components: { ItemSiderbar },

  data() {
    return {
      campanha: null,
      configuracoes: null,
      tipoUsuario: null,

      ultimasConversas: [],

      itensSidebar: [],
      permissaoUsuario: null,
    };
  },

  computed: {
    notificacao() {
      let naoLidas = 0;

      for (let i in this.ultimasConversas) {
        if (this.ultimasConversas[i].naoVisualizadosUsuario > 0) {
          naoLidas = naoLidas + this.ultimasConversas[i].naoVisualizadosUsuario;
        }
      }

      return naoLidas;
    },

    listaSidebar() {
      const itensAutorizados = [];

      const itemPossuiPaginas = (item) => {
        let paginasAutorizadas = [];

        if (this.permissaoUsuario.acessoTotal) {
          paginasAutorizadas = paginasAutorizadas.concat(item.paginas);
        } else {
          const filtrarPaginas = item.paginas.filter((e) =>
            this.permissaoUsuario.paginas.includes(e.caminho)
          );

          if (filtrarPaginas.length) paginasAutorizadas = paginasAutorizadas.concat(filtrarPaginas);
        }

        if (paginasAutorizadas.length)
          itensAutorizados.push({ ...item, paginas: paginasAutorizadas });
      };

      const itemNaoPossuiPaginas = (item) => {
        if (
          this.permissaoUsuario.paginas.includes(item.caminho) ||
          this.permissaoUsuario.acessoTotal
        ) {
          itensAutorizados.push(item);
        }
      };

      this.itensSidebar.forEach((item) => {
        if (item.paginas.length) itemPossuiPaginas(item);
        else itemNaoPossuiPaginas(item);
      });

      return itensAutorizados;
    },
  },

  async beforeMount() {
    this.configuracoes = this.$storeConfig.configuracoes();
    this.tipoUsuario = this.$autenticacao.logado().tipo;
    this.itensSidebar = listaPaginas;
    this.permissaoUsuario = Permissoes.obter();

    // Verificação se ha campanha salva no local storage.
    if (this.$campanha.obterCampanhaAtual()) {
      this.campanha = this.$campanha.obterCampanhaAtual();
    } else {
      this.campanha = null;
    }

    if (this.tipoUsuario == 'lojista') {
      if (this.$router.history.current.path == '/relatorios') return
      if (
        this.configuracoes.MODULO_RASPADINHA_ALTERNATIVA &&
        this.configuracoes.MODULO_CADASTRO_COMPRAS
      ) {
        if (
          this.$router.history.current.path == '/raspadinhasAlternativas' ||
          this.$router.history.current.path == '/validar-venda' ||
          this.$router.history.current.path == '/compras'
        )
          return;
        else
          this.$router.push({
            path: '/raspadinhasAlternativas',
          });
      } else if (this.configuracoes.MODULO_RASPADINHA_ALTERNATIVA)
        this.$router.push({
          path: '/raspadinhasAlternativas',
        });
      else if (this.configuracoes.MODULO_CADASTRO_COMPRAS) {
        if (
          this.$router.history.current.path == '/validar-venda' ||
          this.$router.history.current.path == '/compras'
        )
          return;
        else
          this.$router.push({
            path: '/compras',
          });
      }
    }

    if (this.configuracoes.MODULO_CHAT) {
      this.chat = new this.$chat();
      this.$root.$on('socketRecebido', (msg) => {
        if (this[msg.canal]) {
          this[msg.canal](msg.valor);
        }
      });
    }
  },

  methods: {
    carregaUltimasConversas: function (data) {
      this.ultimasConversas = data;
      let titulo = document.title.replace(/ *\([^)]*\) */g, '');
      if (this.notificacao > 0) {
        titulo = '(' + this.notificacao + ') ' + titulo;
      }

      document.title = titulo;
    },
  },
};
</script>

